footer {
  font-size: 15px;

  .row .row {
    margin: 0 ($grid-gutter-width / -2);
  }

  &#footer {
    padding: $grid-gutter-width 0;

    .logo-uni {
      height: auto;
      width: 200px;
    }

    figcaption {
      margin-top: $grid-gutter-width / 4;
    }

    ul {
      float: none;

      li {
        float: none;
        padding: 0;
        display: block;

        a {
          border: none;
          padding: 0;
          line-height: normal;
        }
      }

      &.ProductNavigation,
      &.MetaNavigation {
        list-style: none;
        margin: $grid-gutter-width 0;
        padding: 0;
        flex: 1 0 auto;

        @media (min-width: map-get($grid-breakpoints, 'md')) {
          margin-top: 0;
          margin-bottom: 0;
        }

        li {
          padding: 0;

          a {
            display: inline-block;
            margin: 5px 0;
          }
        }
      }
    }
  }
}
