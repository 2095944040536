// user changes from the file customHeaderTags.txt located
// in the file admin. related to https://support.sup7.at/issues/11290
// inserted at 2020-09-16

.btn-primary:hover {
  background-color: var(--primary-darker);
  color: var(--white);
}

.floatRight {
  float: right;
}

.kasten {
  border: 1px solid #000;
  background-color: #fff;
  padding: 14px;
  margin: 18px 0;
}

.layout3 {
  font-size: 0.8em;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 16px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*** anpassungen 2020 ***/
header nav a,
.slider .status,
.slider h1,
.slider .h1,
.slider h2,
.slider .h2,
.slider h3,
.slider .h3,
.clocktext,
footer li a,
.btnLogin,
.btnLogout,
.btnSearch {
  text-transform: none;
}

.sliderSmall,
.sliderSmall .container {
  height: 170px;
}

.slider .status {
  margin-top: 10px;
}

div.col-xs-7.col-md-7.offset-md-1.offset-xs-1.hidden-print {
  margin-left: 90px !important;
}

.btnPrint {
  display: none;
}

nav.navbar li a {
  font-size: 17px;
}

.subMenu li a {
  color: var(--gray-700);
}

div.slider div.container h3,
div.slider div.container .h3,
div.slider div.container h1 {
  text-shadow: 2px 2px 5px white;
  color: var(--gray-700);
}

.form-horizontal .control-label {
  text-align: left;
}

.csc-textpic-imagecolumn a:hover {
  background-color: #ddd;
}

h2.csc-firstHeader {
  margin-bottom: 10px !important;
}
