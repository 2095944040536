.panel {
  &__title {
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
  }
  &__button {
    display: flex;
    background: none;
    border: none;
    color: inherit;
    font-weight: inherit;
    align-items: center;

    & > * + * {
      padding-left: 7px;
    }

    &-icon {
      position: relative;
      display: block;
      height: 17px;
      width: 17px;
      background: var(--primary);

      &::before,
      &::after {
        content: '';
        position: absolute;
        background: white;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::before {
        height: 3px;
        width: 10px;
      }

      &::after {
        height: 10px;
        width: 3px;
        opacity: 1;
        transition: opacity 0.3s;
      }
    }

    &[aria-expanded='true'] &-icon {
      &::after {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
  }
}
