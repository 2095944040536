.facet {
  position: relative;

  &__toggle {
    display: flex;
    gap: $grid-gutter-width / 4;
    border: 1px solid var(--gray-300);
    background: transparent;
    width: 100%;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    padding: $grid-gutter-width / 8;
    padding-left: $grid-gutter-width / 4;

    &-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 1;
    }

    &-icon {
      display: block;
      padding: $grid-gutter-width / 8;
      border-radius: 3px;
      background: linear-gradient(to bottom, var(--gray-300), var(--gray-500));
      color: var(--gray-700);

      body.contrast & {
        background: var(--gray-700);
        color: var(--white);
      }

      svg {
        display: block;
        width: $grid-gutter-width / 3 * 2;
        height: $grid-gutter-width / 3 * 2;
      }
    }
  }

  &__list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-top: none;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 350px;
    overflow-y: auto;
  }

  &__option {
    display: block;
    position: relative;
    padding: $grid-gutter-width / 8 $grid-gutter-width / 4;

    &-link {
      display: block;
    }

    &-count {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: $grid-gutter-width / 4;
      top: 0;
      bottom: 0;
    }

    &--hidden {
      display: none;
    }
  }

  &__list--all &__option--hidden {
    display: block;
  }

  &__show-all {
    position: sticky;
    bottom: 0;
    background: var(--white);

    &-button {
      display: block;
      border: none;
      border-top: 1px solid var(--gray-300);
      background: none;
      padding: $grid-gutter-width / 8 0;
      margin: 0;
      width: 100%;

      &:focus {
        outline-offset: -4px;
      }
    }
  }

  &__content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-top: none;
    margin: 0;
    padding: $grid-gutter-width / 4 0 0;
    list-style: none;
    z-index: 1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__range-slider {
    position: relative;
    height: $grid-gutter-width / 2;
    margin: 0 $grid-gutter-width / 4;

    input[type='range'] {
      appearance: none;
      width: 100%;
      height: 100%;
      display: block;
      border: none;
      background: linear-gradient(to left, var(--gray-300), var(--gray-300)) no-repeat center;
      background-size: 100% 4px;
      pointer-events: none;
      position: absolute;

      &:nth-child(2) {
        background: none;
      }

      &::-webkit-slider-thumb {
        width: $grid-gutter-width / 2;
        height: $grid-gutter-width / 2;
        border-radius: 50%;
        background: var(--primary);
        cursor: pointer;
        appearance: none;
        pointer-events: all;
      }

      &::-moz-range-thumb {
        width: $grid-gutter-width / 2;
        height: $grid-gutter-width / 2;
        border-radius: 50%;
        background: var(--primary);
        cursor: pointer;
        appearance: none;
        pointer-events: all;
        border: none;
      }

      &:focus {
        &::-webkit-slider-thumb {
          outline: 2px dashed var(--black);
          outline-offset: 2px;
        }
        &::-moz-range-thumb {
          outline: 2px dashed var(--black);
          outline-offset: 2px;
        }
      }

      &:active {
        &::-webkit-slider-thumb {
          outline: none;
          background: var(--primary-dark);
        }
        &::-moz-range-thumb {
          outline: none;
          background: var(--primary-dark);
        }
      }
    }
  }

  &__range-values {
    display: flex;
    justify-content: space-between;
    margin: $grid-gutter-width / 4;
  }

  &__filter-apply-button {
    display: block;
    border: none;
    border-top: 1px solid var(--gray-300);
    background: none;
    padding: $grid-gutter-width / 8 0;
    margin: 0;
    width: 100%;

    &:focus {
      outline-offset: -4px;
    }
  }

  &--active & {
    &__toggle {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__list {
      display: block;
    }

    &__content {
      display: block;
    }
  }
}
