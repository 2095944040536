a {
  &.no-text-decoration {
    text-decoration: none;
    display: inline-block;

    &:hover {
      text-decoration: none;

      & * {
        color: var(--primary-dark);
      }
    }
  }

  &.btn-back {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  main & {
    font-weight: 700;
  }
}
