.contrast {
  --primary: var(--black);
  --primary-dark: var(--black);
  // --gray-200: var(--white);
  --gray-600: var(--gray-700);
  --gray-500: var(--gray-700);
  --supi-color-allow-background: var(--black);
  --supi-color-individual: var(--black);

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .logos .ce-gallery img {
    filter: contrast(1%);
  }

  .slider .container {
    &:before {
      background: var(--gray-200) !important;
      opacity: 0.8;
    }

    & > * {
      z-index: 2;
    }
  }

  .tx-supi__youtube .tx-supi__youtube-text > div {
    background: rgba(255, 255, 255, 0.97);
  }

  .contrast-switch,
  .top-bar__login,
  .top-bar__burger,
  .btnBuy,
  .external-link-btn,
  .btn-primary,
  .btn.btn-link,
  .scroll-top-wrapper,
  .main-nav__link--language,
  .tx-supi button#supi__individualSwitchTo,
  .tx-supi button#supi__dismiss,
  .tx-supi button.tx-supi__allow,
  .tx-supi button#supi__save {
    --supi-color-choose-invert: var(--white);
    text-decoration: none;

    &:hover {
      --primary-dark: var(--white);
      --primary-light: var(--white);
      --primary-darker: var(--black);
      --supi-color-dismiss-background: var(--black);
      --supi-color-dismiss: var(--white);

      opacity: 0.7;
      transition: 0.3s;

      .fill-primary {
        opacity: 0.7;
      }
    }
  }

  .main-nav {
    &__submenu-toggle {
      text-decoration: none;

      &:hover {
        --gray-300: var(--black);
        text-decoration: none;

        .main-nav__submenu-toggle-icon {
          &::before,
          &::after {
            --gray-700: var(--white);
          }
        }
      }
    }

    &__link {
      text-decoration: none;
    }
  }

  .sub-nav {
    &__link {
      text-decoration: none;
    }
  }

  .btn.btn-gray {
    background: var(--gray-300);
    color: var(--black);
    text-decoration: none;

    &:hover {
      --gray-300: var(--black);
      color: var(--white);
      text-decoration: none;
    }
  }
}
