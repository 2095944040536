#footer .icons {
  list-style: none;
  margin: $grid-gutter-width ($grid-gutter-width / -4) 0 ($grid-gutter-width / -4);
  padding: 0;

  display: flex;

  &-item {
    flex: 0 0 45px;
    padding: 0 ($grid-gutter-width / 4);
  }

  .icon {
    display: block;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.facebook,
    &[href*='facebook'] {
      background: url(../Icons/icon-facebook.svg) no-repeat center;
      background-size: auto 45px;
    }

    &.twitter,
    &[href*='twitter'] {
      background: url(../Icons/icon-twitter.svg) no-repeat center;
      background-size: auto 75px;
    }

    &.youtube,
    &[href*='youtube'] {
      background: url(../Icons/icon-youtube.svg) no-repeat center;
    }
  }
}

.ubi {
  $icon-sizes: 12, 16, 20, 32;

  @each $size in $icon-sizes {
    &.ubi-size-#{$size} {
      height: #{$size}px;
      width: auto;
    }
  }
}
