.top-bar {
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $grid-gutter-width / 2;
  }

  &__right-content {
    display: flex;
    justify-content: flex-end;
    align-self: stretch;
    align-items: center;
  }

  &__burger {
    display: block;
    position: relative;
    width: $grid-gutter-width;
    height: $grid-gutter-width;
    background: transparent;
    border: none;
    padding: 0;
    margin-left: $grid-gutter-width/2;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &-layer {
      background: currentColor;
      width: 100%;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1.5px;

      &--beef,
      &--salad {
        transform-origin: center center;

        @include motion-safe {
          transition: transform 0.3s ease;
        }
      }

      &--salad {
        transform: translateY(-$grid-gutter-width / 4);
      }

      &--cheese {
        @include motion-safe {
          transition: opacity 0.3s ease;
        }
      }

      &--beef {
        transform: translateY($grid-gutter-width / 4);
      }
    }

    &--active &-layer {
      &--salad {
        transform: rotateZ(45deg);
      }

      &--cheese {
        opacity: 0;
      }

      &--beef {
        transform: rotateZ(-45deg);
      }
    }
  }

  &__login {
    display: flex;
    align-self: stretch;
    background: var(--primary);
    padding: 0 $grid-gutter-width / 2;
    align-items: center;
    gap: $grid-gutter-width / 4;

    &,
    &:hover,
    &:focus {
      color: var(--white);
      text-decoration: none;
    }

    &:hover {
      background: var(--primary-aa);
    }

    &:focus {
      outline-offset: -6px !important;
      outline-color: var(--white) !important;
    }
  }

  &__logo {
    padding: $grid-gutter-width / 4 0;
    display: block;
    flex-shrink: 1;

    a {
      display: inline-block;
    }

    svg {
      width: 100%;
      aspect-ratio: 175.7 / 60.3;
    }
  }
}
