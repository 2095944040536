@import 'breakpoints';
@import 'container';
@import 'aspect-ratios';

$fonts-directory: '../Webfonts/';
$font-family-sans-serif: Roboto, sans-serif;

$grid-gutter-width: 32px;

$black: #000;
$white: #fff;
$primary: #f39800;
$primary-light: #f8c166;
$primary-dark: #de9b2f;
$primary-darker: #a26a00;
$primary-aa: #b45f27;
$gray-200: #f2f2f2;
$gray-300: #ddd;
$gray-500: #bdbdbd;
$gray-700: #464646;
$gray-600: #7a7a7a;
$gray-800: #343a40;
$gray-900: #212529;
$green: #94c154;

$light: $gray-200;

$link-color: var(--primary);
$link-hover-color: var(--primary);

$theme-colors: (
  'primary': $primary,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark,
  'primary-darker': $primary-darker,
  'primary-aa': $primary-aa,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'black': $black,
  'white': $white,
  'green': $green,
  light: $light,
);

$spacers: (
  'hg': $grid-gutter-width * 0.5,
  'fg': $grid-gutter-width,
  'dg': $grid-gutter-width * 2,
  'tg': $grid-gutter-width * 3,
  'menu-pages-ul-spacer': 110px,
);
