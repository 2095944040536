/**
  Wrapper
*/
.theme-slider {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;

  &__container {
    position: relative;
  }

  .splide {
    &__price {
      font-family: 'Bosk';
      font-size: 28px;
    }

    &__pagination {
      position: static;
      top: unset;
      left: unset;
      bottom: unset;
      transform: none;
      flex-wrap: nowrap;
      min-height: 46px;
      flex: 0 auto;
    }

    &__progress__bar {
      background-color: var(--primary);

      @media screen and (prefers-reduced-motion: reduce) {
        width: 100% !important;
      }
    }

    &__pause-play {
      border: none;
      color: var(--white);
      padding: $grid-gutter-width / 8 $grid-gutter-width / 4;
      background-color: var(--primary);
      outline-offset: -3px;
      min-width: 60px;

      @media screen and (prefers-reduced-motion: reduce) {
        display: none;
      }
    }

    &__banner {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
      }
    }

    &__slide {
      display: flex;
      align-items: center;
    }
  }

  .previous-button,
  .next-button {
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    line-height: 1;
  }
}

/**
  Controls - pause/resume, previous, and next buttons
*/

/* Resets for all buttons */
.is-control {
  z-index: 5;
  background: none;
  color: rgba(0, 0, 0, 0.7);
  border: 0;
  cursor: pointer;
  transition: all 0.2s linear;

  &__svg-wrapper {
    border: 1px solid transparent;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: 15px;
    width: 15px;
  }
}

/**
  Single slide
*/
.splide__slide {
  position: relative;
  height: auto;
  visibility: hidden;

  .h2 {
    font-family: 'Bosk';
    color: var(--primary);
    font-size: 32px;
    margin-bottom: $grid-gutter-width / 4;
  }
}

.splide__slide.is-visible {
  visibility: visible;
}

.splide__pagination {
  bottom: -35px;
}

.splide__pagination {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li + li {
    margin-left: 10px;
  }
}

/* Create a circle to serve as the slide dot */
.splide__pagination li button {
  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 15px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #212529;
}

/* Give the focused slide dot a nice visible focus indicator */
.splide__pagination li button:focus,
.splide__pagination li button.is-active:focus {
  outline-offset: 3px;
  outline: 2px dashed var(--primary);
}

/* Leverage aria-current for styling to ensure we're using the attribute correctly */
.splide__pagination li button[aria-current='true'] {
  background-color: $dark;
  transform: none;
  opacity: 1;
}
