.scroll-top-wrapper {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  text-align: center;
  z-index: 99999999;
  /*color: #FFF;*/
  width: 48px;
  height: 48px;
  line-height: 48px;
  right: 15px;
  bottom: 30px;
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  &.show {
    visibility: visible;
    cursor: pointer;
    opacity: 1;
  }

  i.fa {
    line-height: inherit;
  }

  &:hover {
    svg {
      --primary: var(--primary-aa);
    }
  }
}
