:root {
  --supi-position-bottom: 0;
  --supi-position-left: 0;
  --supi-position-width: 100%;

  @include supi-media-breakpoint-up(md) {
    --supi-position-bottom: #{map-get($supi-position, bottom)};
    --supi-position-left: #{map-get($supi-position, left)};
    --supi-position-width: 40%;
  }
  --supi-color-keyhole: #{map-get($supi-colors, success)};
  --supi-color-background: #{map-get($supi-colors, background)};
  --supi-color-primary: #{map-get($supi-colors, primary)};
  --supi-color-secondary: #{map-get($supi-colors, secondary)};
  --supi-color-individual: #{map-get($supi-colors, success)};
  --supi-color-individual-background: #{map-get($supi-colors, background)};
  --supi-color-allow: #{map-get($supi-colors, background)};
  --supi-color-allow-background: #{map-get($supi-colors, success)};
  --supi-color-dismiss: #{map-get($supi-colors, primary)};
  --supi-color-dismiss-background: #{map-get($supi-colors, warning)};
  --supi-color-choose: #{map-get($supi-colors, notice)};
  --supi-color-choose-invert: #{map-get($supi-colors, secondary)};
  --supi-color-button-bar: #{map-get($supi-colors, button-bar)};
  --supi-color-button-color: #{map-get($supi-colors, background)};
  --supi-padding: #{$supi-banner-box-padding};
  --supi-margin: #{$supi-banner-box-margin};
  --supi-font-base-size: #{$supi-font-base-size};
  --supi-font-size: #{$supi-font-size};
  --supi-font-size-head: #{$supi-font-size-head};
  --supi-color-underline: #{map-get($supi-colors, underline)};

  @if $supi-border {
    --supi-banner-border: #{$supi-banner-box-border};
  }

  @if $supi-box-shadow {
    --supi-banner-box-shadow: #{$supi-banner-box-shadow};
  }

  --supi-button-wrap-bgcolor: #{map-get($supi-colors, button-wrap-bgcolor)};
  --supi-button-wrap-padding: #{$supi-button-wrap-padding};

  --supi-transform-value: calc(100% + 30px);
}

.tx-supi {
  display: flex;
  max-height: 100%;

  &__overlay {
    overflow: hidden;
  }

  &__button {
    flex-direction: column;

    &-wrap {
      background: #{map-get($supi-colors, button-wrap-bgcolor)};
      background: var(--supi-button-wrap-bgcolor);

      padding: #{$supi-button-wrap-padding};
      padding: var(--supi-button-wrap-padding);
    }
  }

  &__pane-hidden {
    display: none;
  }

  &.position {
    flex-direction: column;
    position: fixed;
    z-index: 999999;
    background-color: #fff;
    transition: all 0.2s ease-in-out;

    font-size: $supi-font-base-size;
    font-size: var(--supi-font-base-size);

    &-bottom {
      &-fullwidth {
        bottom: 0;
        max-width: none;
        width: 100%;
      }

      &-left {
        bottom: 0;
        bottom: var(--supi-position-bottom);

        left: 0;
        left: var(--supi-position-left);

        width: 100%;

        @include supi-media-breakpoint-up(md) {
          bottom: #{map-get($supi-position, bottom)};
          bottom: var(--supi-position-bottom);

          left: #{map-get($supi-position, left)};
          left: var(--supi-position-left);

          flex-grow: 1;
          flex-shrink: 1;

          flex-basis: 40%;
          flex-basis: var(--supi-position-width);

          max-width: 40%;
          max-width: var(--supi-position-width);
        }
      }
    }

    &.hide {
      transform: translateY(100%);
      transform: translateY(var(--supi-transform-value));
    }

    &.hide .hide,
    & .hide {
      transform: none;
      display: none;
    }
  }

  &.theme {
    background-color: #{map-get($supi-colors, background)};
    background-color: var(--supi-color-background);

    color: #{map-get($supi-colors, primary)};
    color: var(--supi-color-primary);

    &-default {
      @if $supi-border {
        border-top: #{$supi-banner-box-border};
        border-top: var(--supi-banner-border);
      }
      max-width: 800px;
      width: 100%;
      overflow-y: auto;

      @include supi-media-breakpoint-up(md) {
        @if $supi-border {
          border: #{$supi-banner-box-border};
          border: var(--supi-banner-border);
        }
      }

      @if $supi-box-shadow {
        box-shadow: #{$supi-banner-box-shadow};
      }

      box-shadow: var(--supi-banner-box-shadow);

      font-size: #{$supi-font-size};
      font-size: var(--supi-font-size);

      line-height: 1.5;

      #supi__description,
      .elements {
        padding: #{$supi-banner-box-padding};
        padding: var(--supi-padding);
      }

      h3,
      .tx-supi__link-button {
        color: inherit;
      }

      h3 {
        font-size: #{$supi-font-size-head};
        font-size: var(--supi-font-size-head);

        line-height: inherit;
        margin: 0 0 0.5em 0;
      }

      p {
        margin: 0;
      }

      a,
      .tx-supi__link-button {
        background: transparent;
        padding: 0;
        display: inline-block;
        color: #{map-get($supi-colors, primary)};
        color: var(--supi-color-primary);
        text-decoration: underline;
        vertical-align: bottom;
        text-align: left;

        &:after {
          display: none;
        }
      }

      .tx-supi__link-button[aria-expanded] {
        text-decoration: none;
      }

      .supi {
        &-element {
          &__label {
            display: block;
            font-weight: bold;
          }
        }
      }

      .tx-supi__buttongroup {
        ul {
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 0;
          margin: 0;

          @include supi-media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
          }

          li {
            display: flex;
            justify-content: center;

            & + li {
              margin-top: $supi-grid-gutter-width * 0.5;

              @include supi-media-breakpoint-up(md) {
                margin-top: 0;
              }
            }

            button {
              flex: auto;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  button {
    border: none;
    line-height: 1.3;
    color: var(--supi-color-button-color);
    text-decoration: none;
    text-align: center;
    font-family: inherit;
    font-weight: normal;
    padding: $supi-grid-gutter-width * 0.5;

    &:after,
    &:before {
      display: none;
    }

    &#supi__individualSwitchTo {
      border: 2px solid #{map-get($supi-colors, success)};
      border: 2px solid var(--supi-color-individual);

      background: #{map-get($supi-colors, background)};
      background: var(--supi-color-individual-background);

      color: #{map-get($supi-colors, primary)};
      color: var(--supi-color-primary);
    }

    &.tx-supi__allow {
      background: #{map-get($supi-colors, success)};
      background: var(--supi-color-allow-background);

      color: #{map-get($supi-colors, background)};
      color: var(--supi-color-allow);
    }

    &#supi__dismiss {
      background: #{map-get($supi-colors, warning)};
      background: var(--supi-color-dismiss-background);

      color: #{map-get($supi-colors, primary)};
      color: var(--supi-color-dismiss);
    }

    &#supi__choose,
    &#supi__save {
      background: #{map-get($supi-colors, notice)};
      background: var(--supi-color-choose);

      color: #{map-get($supi-colors, secondary)};
      color: var(--supi-color-choose-invert);

      display: inline-block;
      padding-left: #{$supi-banner-box-padding};
      padding-left: var(--supi-padding);

      padding-right: #{$supi-banner-box-padding};
      padding-right: var(--supi-padding);
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#fff, 0.9);
    display: flex;
    justify-content: center;
    z-index: 999999;
    align-items: flex-end;

    @include supi-media-breakpoint-up(md) {
      align-items: center;
    }

    &.hide {
      display: none;
    }
  }

  &__block {
    overflow-x: hidden;

    & &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .tx-supi__link-button {
        font-weight: bold;
        position: relative;
        padding-left: 15px;
        text-decoration: none;

        &:before {
          content: '';
          width: 7px;
          height: 7px;
          border-left: 2px solid map-get($supi-colors, primary);
          border-top: 2px solid map-get($supi-colors, primary);
          position: absolute;
          top: 10px;
          left: 0;
          transform: rotate(135deg);
          transition: transform 0.2s ease-in-out;
          display: block;
        }

        &.tx-supi__pane-active:before {
          top: 9px;
          transform: rotate(225deg);
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: $supi-grid-gutter-width;
    }

    &-item {
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        small {
          opacity: 0.7;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: $supi-grid-gutter-width * 0.5;

        &-toggle {
          position: relative;

          &:before {
            content: '';
            width: 7px;
            height: 7px;
            border-left: 2px solid map-get($supi-colors, primary);
            border-top: 2px solid map-get($supi-colors, primary);
            position: absolute;
            top: 7px;
            left: -13px;
            transform: rotate(135deg);
            transition: transform 0.2s ease-in-out;
            display: block;
          }

          &.open:before {
            transform: rotate(225deg);
          }
        }
      }

      &__header {
        display: flex;
        justify-content: space-between;
      }

      &__table {
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: transform 0.2s ease-in-out;
        padding-top: $supi-grid-gutter-width * 0.5;

        & > * {
          tr {
            display: flex;
            flex-wrap: wrap;

            @include supi-media-breakpoint-up(md) {
              flex-wrap: nowrap;
            }
          }

          th {
            flex: 0 0 100%;
            padding: 4px 16px 4px 0;

            @include supi-media-breakpoint-up(md) {
              flex: 0 0 138px;
            }
          }

          td {
            flex: 1;
            padding: 4px 8px 4px 0;
          }
        }

        & > * + * {
          margin-top: $supi-grid-gutter-width * 0.5;
        }

        &:not([hidden]) {
          height: auto;
          overflow: visible;
        }

        &-overview {
          width: 100%;
        }

        &-cookie {
          width: 100%;
          background: #{map-get($supi-colors, cookie-table-bgcolor)};
          box-shadow: 0px 0px 2px #00000040;

          th {
            flex: 0 0 100%;

            @include supi-media-breakpoint-up(md) {
              flex: 0 0 130px;
            }
          }

          th,
          td {
            padding: 4px 8px;
          }
        }
      }
    }
  }

  .supi {
    &-checkbox {
      order: 1;
      display: flex;
      align-items: center;

      input {
        appearance: none;
        position: absolute;

        &:checked {
          & + label {
            background: #{map-get($supi-colors, success)};
            box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #{map-get($supi-colors, success)};

            &:before {
              transform: translateX(20px);
              box-shadow: 1px 1px rgba(0, 0, 0, 0.2), 0 0 0 2px #{map-get($supi-colors, success)};
            }
          }

          & + label svg {
            transform: translate(-50%, -50%);
            transition: 0.1s ease-in-out;
          }
        }

        &:disabled {
          & + label {
            opacity: 0.6;
          }

          & + label svg {
            opacity: 1;
          }
        }
      }

      label {
        display: inline-block;
        position: relative;
        cursor: pointer;
        width: 40px;
        height: 20px;
        border-radius: 20px;
        background: #f8f8f8;
        box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #ddd;
        transition: 0.1s ease-in-out;
        margin: 0;
        color: #{map-get($supi-colors, success)};
        color: var(--supi-color-keyhole);

        &:before {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: #fff;
          box-shadow: 1px 1px rgba(0, 0, 0, 0.2), 0 0 0 2px #ddd;
          transition: 0.1s ease-in-out;
        }
      }

      svg {
        position: absolute;
        display: block;
        opacity: 0;
        top: 50%;
        right: 4px;
        transform: translate(-400%, -50%);
        transition: 0.1s ease-in-out;
      }
    }
  }

  [data-toggle] {
    cursor: pointer;
  }
}

// Youtube element

.tx-supi__youtube {
  position: relative;

  &-text {
    position: absolute;
    top: 30%;
    text-align: center;
    margin: 0 40px;
    padding: 40px 12px;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
  }
}

.tx_supi__map {
  &-content {
    display: none;
  }

  .active &-content {
    display: block;
  }

  .active &-text {
    display: none;
  }
}

input[id^='supi'] {
  outline: none;
}

.js-focus-visible input[id^='supi'] {
  outline: none;
}

.tx-supi {
  a,
  button,
  &__link-button {
    &:focus-visible {
      box-shadow: none;
      outline-offset: 0;
      overflow: hidden;
      outline: #{map-get($supi-colors, primary)} solid 3px;
      outline: var(--supi-color-primary) solid 3px;
      transition: none;
    }
    // Duplicated because some legacy browser suppressing the rule completely
    &.focus-visible {
      box-shadow: none;
      outline-offset: 0;
      overflow: hidden;
      outline: #{map-get($supi-colors, primary)} solid 3px;
      outline: var(--supi-color-primary) solid 3px;
      transition: none;
    }
  }

  &__link-button {
    &[aria-expanded] {
      position: relative;
      font-size: 16px;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        border-left: 2px solid map-get($supi-colors, primary);
        border-top: 2px solid map-get($supi-colors, primary);
        position: absolute;
        top: 6px;
        left: -17px;
        transform: rotate(135deg);
        transition: transform 0.2s ease-in-out;
        display: block;
      }
    }

    &[aria-expanded='true'] {
      &:before {
        transform: rotate(225deg);
      }
    }
  }

  input[id^='supi'] {
    &:focus-visible + label {
      outline-offset: 3px;
      overflow: hidden;
      outline: #{map-get($supi-colors, primary)} solid 3px;
      outline: var(--supi-color-primary) solid 3px;
      transition: none;
    }
  }

  input[id^='supi'] {
    &.focus-visible + label {
      outline-offset: 3px;
      overflow: hidden;
      outline: #{map-get($supi-colors, primary)} solid 3px;
      outline: var(--supi-color-primary) solid 3px;
      transition: none;
    }
  }

  button {
    &:focus-visible {
      outline-offset: -3px;
    }
    &.focus-visible {
      outline-offset: -3px;
    }
  }

  button.tx-supi__link-button {
    &:focus-visible {
      outline-offset: 0;
    }
    &.focus-visible {
      outline-offset: 0;
    }
  }

  #supi__choose {
    &:focus-visible {
      outline-offset: 3px;
    }
    &.focus-visible {
      outline-offset: 3px;
    }
  }

  &__label {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  &__underline {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #{map-get($supi-colors, underline)};
    border-bottom-color: var(--supi-color-underline);
  }
}
