.tx-supi__youtube {
  @include css-grid;

  &-text {
    position: relative;
    top: auto;
    margin: 0;
    background: none;
    padding: $grid-gutter-width/2;
    display: flex;
    align-items: center;

    @include css-grid('layer-1');

    > div {
      background: rgba(255, 255, 255, 0.9);
      text-align: center;
      padding: $grid-gutter-width;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: $font-size-lg;
    }
  }

  figure {
    margin: 0;

    @include css-grid('layer-2');
    -ms-grid-row: 2;
    grid-row: 2;

    @include media-breakpoint-up(md) {
      -ms-grid-row: 1;
      grid-row: 1;
    }
  }
}

.frame-type-tx_supi_youtube iframe {
  @extend .aspect-ratio-xs-item;
}

// possible ie11 bugfixes needed. i got a hack for that, but only if
// we really need a ie11 bugfix
