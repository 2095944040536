.footer-nav {
  &__container {
    flex: 1 0 auto;
  }

  &__title {
    font-size: 15px;
    margin: 5px 0;
    font-weight: 700;
    line-height: 1;
  }
}
