.form {
  &-group {
    @extend .row;
  }

  &-control {
    border-color: var(--gray-500);
    height: 40px;
    padding: 0.5rem 0.7rem;

    &::placeholder {
      color: var(--gray-600);
    }
  }
}
