// legacy code was
// the floating killed the layout in firefox. dont know why it was floating on a 100% width
//hr{clear:both;float:left;width:100%;height:1px;border:none;background-color:#cccccc;margin:50px 0}

hr {
  height: 1px;
  border: none;
  background-color: __get($theme-colors, 'gray-600');
  margin: 50px 0;
}
