.product {
  .row {
    position: relative;
  }

  [class^='col'] {
    position: static;
  }

  &-titel {
    color: map-get($theme-colors, orange);
    font-weight: 500;
  }

  &Preis {
    display: flex;
    flex-direction: column;

    .product-desc {
      margin-bottom: 0;
    }

    .product-price {
      color: map-get($theme-colors, orange);
      font-size: 25px;
      font-weight: 500;
    }
  }

  &-table {
    tr td,
    tr th {
      padding: 5px 10px;
      border: 2px solid #fff;
      vertical-align: top;
    }

    tr th {
      background-color: var(--gray-500);
      color: #fff;
      text-align: right;
    }

    tr td {
      background-color: #e5e5e5;
      color: var(--gray-700) 0;
    }
  }
}

/** for usage in backstop tests **/
.img-placeholder {
  display: flex;
  width: 100%;
  background: map-get($theme-colors, grey);
  height: 250px;
  align-items: center;
  justify-content: center;
  color: #fff;

  &-tn {
    height: 50px;
    width: 80px;
  }
}

input[type='checkbox'].checkbox-compare {
  // display: none; Never use this on checkbox input, because then the checkbox ist hidden vor SR and tab-order, use sr-only class.
  & + label {
    cursor: pointer;
    .checked {
      display: none;
    }
  }

  &:checked + label {
    .checked {
      display: block;
    }
    .unchecked {
      display: none;
    }
  }
}

.clickarea {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
