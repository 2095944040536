.sub-nav {
  background: var(--gray-200);
  border-top: 1px solid var(--white);
  display: none;

  body.contrast & {
    border-bottom: 1px solid var(--black);
    border-top-color: var(--black);
  }

  @include media-breakpoint-up(md) {
    display: block;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__list-item {
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
    color: inherit;
    font-size: 1.125rem;

    @include motion-safe {
      transition: background-color 0.2s ease;
    }

    &,
    &:focus,
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:hover,
    &:focus {
      background: var(--gray-300);
    }

    &--active {
      &,
      &:hover,
      &:focus {
        color: var(--primary);

        body.contrast & {
          font-weight: bold;
        }
      }
    }
  }
}
