/*
    $ css/style.css | 2015-07-29 12:05 | 2015-07-29 00:00 $
    $ http://www.ubook.at $
    $
    $ ----------------------------------------------$
    $ colors (general):
    $ ----------------------------------------------$
    $ Orange 1                             - var(--primary-light)
    $ Orange 2 (btnSearc:hover)            - var(--primary)
    $ Grey 1 (bg)                          - #f2f2f2
    $ Grey 2 (nav a)                       - #707173
    $ Grey 3 (text)                        - var(--gray-700)
    $ Grey 4 (h2)                          - var(--gray-700)
    $ ----------------------------------------------$
*/

/* GENERAL
----------------------------------------------------------------------------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; /*outline:0!important*/
}
/*body,html{width:100%;height:100%;}*/
html {
  color: var(--gray-700);
  font:
    normal 400 15px/22px 'Roboto',
    sans-serif;
}
body {
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}
p,
p.bodtext {
  color: var(--gray-700) 0;
  line-height: 1.5em;
}
a {
  text-decoration: none;
}
a.external-link-new-window {
  background: url(../Images/externalLink.svg) center right no-repeat;
  background-size: 10px 10px;
  padding-right: 15px;
}
ul,
ol {
  margin-left: 20px;
}
ul.breadcrumbs {
  margin-left: 0;
}
input,
textarea {
  outline: none !important;
  resize: none;
}
.showPrint {
  display: none;
}
.container-fluid {
  padding: 0;
}
.container-overflow {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container-overflow h2 {
  margin: 25px 0 15px 0;
}
section {
  width: 100%;
  position: relative;
  vertical-align: baseline;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border: 0;
}
/* animations */
.animAll,
a {
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
}
.animColor {
  -webkit-transition: color 0.35s ease-out;
  -moz-transition: color 0.35s ease-out;
  transition: color 0.35s ease-out;
  -o-transition: color 0.35s ease-out;
}
.animOpacity {
  -webkit-transition: opacity 0.35s ease-out;
  -moz-transition: opacity 0.35s ease-out;
  transition: opacity 0.35s ease-out;
  -o-transition: opacity 0.35s ease-out;
}
.animBorderColor {
  -webkit-transition: border-color 0.35s ease-out;
  -moz-transition: border-color 0.35s ease-out;
  transition: border-color 0.35s ease-out;
  -o-transition: border-color 0.35s ease-out;
}
.animBg {
  -webkit-transition: background-color 0.35s ease-out;
  -moz-transition: background-color 0.35s ease-out;
  transition: background-color 0.35s ease-out;
  -o-transition: background-color 0.35s ease-out;
}
.animTop {
  -webkit-transition: top 0.35s ease-out;
  -moz-transition: top 0.35s ease-out;
  transition: top 0.35s ease-out;
  -o-transition: top 0.35s ease-out;
}
.animBottom {
  -webkit-transition: bottom 0.35s ease-out;
  -moz-transition: bottom 0.35s ease-out;
  transition: bottom 0.35s ease-out;
  -o-transition: bottom 0.35s ease-out;
}
.animLeft {
  -webkit-transition: left 0.35s ease-out;
  -moz-transition: left 0.35s ease-out;
  transition: left 0.35s ease-out;
  -o-transition: left 0.35s ease-out;
}
.animRight {
  -webkit-transition: right 0.35s ease-out;
  -moz-transition: right 0.35s ease-out;
  transition: right 0.35s ease-out;
  -o-transition: right 0.35s ease-out;
}

/*.container{padding: 0 25px;}
.row {margin-right: -15px;margin-left: -15px;}*/
/*.slider .row {margin: 0}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {padding-left:30px;padding-right:30px;}
*/
.row.gridelements {
  margin: 0 -15px;
}
.row.gridelements h2 {
  margin: 50px 0 0 0;
}

/* padding */
.noPadding {
  padding: 0;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
}
.p40 {
  padding: 40px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt40 {
  padding-top: 40px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb40 {
  padding-bottom: 40px;
}
.ps5 {
  padding-left: 5px;
  padding-right: 5px;
}
.ps10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ps15 {
  padding-left: 15px;
  padding-right: 15px;
}
.ps20 {
  padding-left: 20px;
  padding-right: 20px;
}
.ps30 {
  padding-left: 30px;
  padding-right: 30px;
}
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
/* margin */
.noMargin {
  margin: 0;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px;
}
.m20 {
  margin: 20px;
}
.m30 {
  margin: 30px;
}
.m40 {
  margin: 40px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.ms10 {
  margin-left: 10px;
  margin-right: 10px;
}
.ms15 {
  margin-left: 15px;
  margin-right: 15px;
}
.ms20 {
  margin-left: 20px;
  margin-right: 20px;
}
.ms30 {
  margin-left: 30px;
  margin-right: 30px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.mb30 {
  margin-bottom: 30px;
}

.infobox {
  margin-bottom: 40px;
  padding-bottom: 10px;
}
.infobox h2 {
  margin: 20px 0;
}

/* Colors */
.bgGrey {
  background-color: #f2f2f2;
}

/* Borders */
.btWhite {
  border-top: 1px solid #fff;
}
.bbWhite {
  border-bottom: 1px solid #fff;
}

.sidebar h2 {
  margin: 0px 0 10px 0;
}
.sidebar.bgGrey > div:first-child h2 {
  margin: 0 0 10px 0;
}
.sidebar > div {
  padding-bottom: 15px;
}

.content-intented {
  margin: 0 30px;
}
strong,
b {
  font-weight: 700;
}
article a {
  color: var(--primary);
  font-weight: 700;
}
.tx-irfaq-pi1 ul li a {
  color: var(--primary) !important;
  font-weight: 700;
}

/* Nav*/
.navbar-toggle {
  border: 0;
  margin: 0 3px 0 0px;
  padding: 36px 15px;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: var(--gray-700);
}

/* HEADER
----------------------------------------------------------------------------------------------------------------------------- */
/*.logo{display:block;width:0px;height:0px;margin:0;background-image:url(123.jpg) no-repeat 0 0;text-indent:-50000px}
 Menü * /
 .topNav .row {margin: 0;overflow: hidden;}
header ul{list-style-type:none;margin:0}
header li{float:left}
.navbar-brand{float: left;font-size: 18px;line-height: 20px;padding:10px 0 0 0;margin-left:-10px;height: auto;}
.btnLogin,.btnSearch,.btnLogout{display:inline-block;height:86px;padding:31px 15px 0 15px;text-transform:uppercase;font-size:14px;}
.btnLogin,.btnLogout{color:#fff;background-color:var(--primary);}
.btnLogout i {margin-right: 5px;}
.btnSearch{color:#000;}
.btnLogin span,.btnSearch span{margin: 0 0 0 5px;}
.btnSearch span{}
.btnLogin span{}
.navbar {min-height: 0;}
#mainMenu{padding: 0;}
.mainMenu .navbar-nav li.active > a{color:var(--primary);font-weight: 700;}
.navbar-nav > li > a {padding:24px 14px 15px 14px;}
.btnLogin:hover, .external-link-btn:hover {text-decoration:none;color:var(--primary-light)}
.btnSearch:hover{text-decoration:none;opacity:.4}
.topButtons{border-left:1px solid #e5e5e5}
header nav a{text-transform:uppercase;color:var(--gray-700);letter-spacing: -0.02em;}
.subMenu {height: 40px;}
.subMenu .navbar-nav > li > a {padding:13px 14px 7px 14px;}
.subMenu .navbar-nav > li.active > a {padding:13px 14px 5px 14px;}
.subMenu .navbar-toggle{float:left}
.subMenu li a{font-size:14px;color:#000}
.subMenu .active a{color:#000;border-bottom:2px solid var(--primary) !important;font-weight:700}
/* Slider */
.slider {
  height: 372px;
}
.slider h1,
.slider h2,
.slider h3,
.slider .h1,
.slider .h2,
.slider .h3 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
.slider h1,
.slider .h1 {
  font-size: 52px;
  line-height: 52px;
  font-weight: 700;
}
.slider h2,
.slider .h2 {
  font-size: 42px;
  line-height: 42px;
  font-weight: 400;
}
.slider h3,
.slider .h3 {
  font-size: 28px;
  line-height: 28px;
  font-weight: 400;
}
.slider .status {
  display: inline-block;
  padding: 5px 7px;
  margin-bottom: 10px;
  margin-top: 40px;
  background-color: var(--primary);
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2em;
}
.slider .col-md-12 {
  padding: 0;
}
.slider .container {
  padding-top: 0px;
  height: 372px;
}
/* Slider small */
.sliderSmall {
  height: 224px;
  overflow: hidden;
}
.sliderSmall .container {
  padding-top: 0px;
  height: 224px;
}
/* Logos */
.logos {
  text-align: center;
  width: 100%;
  clear: left;
  display: block;
  float: left;
}
.logos a {
  display: block;
  text-indent: -50000px;
  padding-left: 20px;
  padding-right: 20px;
}
.logos a:hover span {
  opacity: 0.6;
}
.logos a span {
  display: block;
  height: 50px;
}
.logoA1 span {
  width: 41px;
  height: 50px;
}
.logoApple span {
  width: 41px;
  height: 50px;
}
.logoMicrosoft span {
  width: 137px;
  height: 29px;
  padding-top: 10px;
}
.logoHP span {
  width: 48px;
  height: 48px;
}
.logoLenovo span {
  width: 115px;
  height: 19px;
  padding-top: 15px;
}
.logos li {
  float: none;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

/* CONTENT
----------------------------------------------------------------------------------------------------------------------------- */
.block4 .icon span {
  display: block;
  margin: 0 auto;
  width: 50px;
}
.blockTeilnehmer .icon span {
  width: 64px;
}
p.date {
  margin: 0;
  padding: 0;
  color: var(--primary);
  font-size: 13px;
  font-weight: 500;
}
.btnMore {
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  background: url(../Images/externalLink.svg) center right no-repeat;
  background-size: 11px 11px;
  padding: 0 15px 0 5px;
}
.yellowIcon {
  display: block;
  float: left;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.blockPre ul {
  float: left;
  width: 100%;
  list-style-type: none;
}
.blockPre ul li {
  float: left;
  margin-top: 10px;
  margin-right: 20px;
  line-height: 45px;
}
/* Breadcrumbs */
.breadcrumbs {
  list-style-type: none;
}
.breadcrumbs li {
  float: left;
  color: var(--gray-600) 2;
  margin-right: 3px;
  font-size: 13px;
}
.breadcrumbs a {
  color: var(--gray-600) 2;
  font-weight: 400;
}
.breadcrumbs .active {
  color: var(--primary);
  font-weight: 700;
}
//.btnPrint{font-size:14px;color:var(--primary);padding-right:25px;background:url(../Images/printer.svg) no-repeat right center; background-size: 15px 15px;}
form + hr {
  margin-top: 20px;
}

/* news */
.m-news .article {
  border-bottom: 1px solid var(--gray-300);
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.news-list-title {
  color: var(--gray-700) 0;
  font-size: 18px;
  font-weight: 700;
  margin: 5px 0 10px 0;
}
.news-details-title {
  color: var(--gray-700) 0;
  font-size: 18px;
  font-weight: 700;
  margin: 5px 0 15px 0;
}
.news-list-date {
  color: var(--gray-700); /*font-weight: 13px !important;*/
}
.m-news .teaser-text a {
  color: var(--gray-700) 0;
  font-weight: 400;
  line-height: 1.5em;
}
.m-news a:hover {
  text-decoration: none;
}
.m-news a.more:hover {
  text-decoration: underline;
}
.m-news a.more {
  color: var(--primary);
  font-weight: 700;
}
.view-detail .news-backlink-wrap {
  float: right;
}

/* Product list, Product details */
.tx-ubookproducts {
  margin-top: 20px;
}
.products .icons,
.productDetails .icons {
  list-style-type: none;
}
.products .icons li,
.productDetails .icons li {
  float: left;
  width: 33px;
  height: 33px;
  margin-right: 3px;
  margin-bottom: 15px;
}
.products table,
.productDetails table {
  margin-bottom: 40px;
  font-size: 14px;
}
.products h5,
.products .h5,
.productDetails h5,
.productDetails .h5 {
  margin: 0;
  padding: 0;
  font-size: 25px;
  color: var(--primary);
  font-weight: 900;
}
.products p {
  margin: 0;
  padding: 0;
  color: var(--gray-700);
}
.products > div:first-child {
  height: 210px;
}
#solr_products.products > div:first-child {
  height: inherit;
}

.products h2,
.productDetails h2 {
  margin: 2px 0 3px 0;
  padding: 0;
  font-size: 28px;
}
.products h2 a,
.productDetails h2 {
  color: var(--gray-700);
  font-weight: 400;
}
.products h2 a:hover {
  text-decoration: none;
}
.products h3,
.productDetails h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  color: var(--primary);
}
.productPreis {
  margin-bottom: 20px;
}
.productPreis p {
  color: var(--gray-700);
  margin-bottom: 0;
}
.product > div > div > a.btnMore,
.productDetails > div > div > a.btnMore {
  background-image: none;
}
.product small {
  line-height: 1.2em;
  display: block;
}

.btnDetails,
.btnBuy,
.btn {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 10px 5px;
}
.btnDetails:hover,
.btnBuy:hover {
  text-decoration: none;
}
.btnDetails {
  background-color: var(--gray-600);
  margin-right: 5px;
}
.btnDetails:hover {
  color: #bbb;
}
.btnBuy {
  background-color: var(--primary);
  margin-bottom: 5px;
}
.btnBuy:hover {
  color: var(--white);
}
select {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #f2f2f2;
  color: var(--gray-700) 0;
  font-size: 14px;
  padding: 5px;
}
option {
  padding: 2px 10px;
}
.formLabel {
  color: var(--primary);
  font-weight: 700;
  padding-top: 5px;
}

.products label,
.productDetails label {
  cursor: pointer;
  font-weight: 400;
  margin-left: 4px;
  margin-bottom: 0;
}
.products label:hover,
.productDetails label:hover {
  color: var(--primary);
}
.products .productImage:hover,
.productDetails .productImage:hover {
  opacity: 0.7;
}
.panel-title a {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 12px;
}
.panel-title a:hover {
  color: var(--primary);
}
.panel-title a:hover,
.panel-title a:focus {
  text-decoration: none;
}
.panel-title a span {
  display: block;
  float: right;
  width: 20px;
  height: 20px;
  background: var(--primary) url(../Images/iconPlus.svg) no-repeat center center;
}
.panel-title a.collapsed span {
  background: var(--primary) url(../Images/iconMinus.svg) no-repeat center center;
}
.formLabel a span {
  display: block;
  float: right;
  width: 20px;
  height: 20px;
  background: var(--primary) url(../Images/iconMinus.svg) no-repeat center center;
}
.formLabel a.collapsed span {
  background: var(--primary) url(../Images/iconPlus.svg) no-repeat center center;
}
.panel-collapse {
  margin: 0 -2px;
}
.product-comparison > div:first-child {
  border-right: 1px solid #cccccc;
  margin-right: -1px;
}
//.product-comparison table tr td {border: 1px solid #cccccc; border-right:none; border-collapse: collapse;padding:19px 15px;vertical-align:top;}
//.product-comparison table tr td:first-child {border-top:none;vertical-align:top;}
//.product-comparison table tr td p {font-size:14px;margin: 0;}
//.product-comparison table.product-comparison-caption {width: 100%;background-color: #ffffff;}
//.product-comparison table.product-comparison-caption tr td {border-left: none;text-align: right}
//.product-comparison table.product-comparison-caption tr td p {font-size:14px;color: #000000;}
//.product-comparison table tr:first-child td {margin: 0; padding: 0; border: none;}
//.product-comparison table tr:last-child td {border: none}
//.product-comparison .product-comparison-tr-header{}
//.product-comparison .product-comparison-tr-footer{height:100px;}
.product-comparison .slick-slide table,
.slick-initialized .slick-slide {
  max-width: calc(100vw - 90px);
}
.product-comparison .productPreis {
  margin-bottom: 0;
}
.product-comparison .productPreis p {
  font-size: 12px;
}
.product-comparison .productPreis h5 {
  font-size: 25px;
}

.product-comparison {
  padding-top: 40px;
}
#product-comparison-slider .products h2 {
  font-size: 18px;
}
#product-comparison-slider .products h3 {
  font-size: 14px;
}
#product-comparison-slider .btnDetails,
#product-comparison-slider .btnBuy {
  display: inline-block;
  margin: 0 0 10px 0;
}
#product-comparison-slider button.slick-prev {
  background: transparent url(../Images/pfeil_links.svg) no-repeat right center;
  height: 96px;
  width: 34px;
  border: none;
  margin-left: -45px;
  margin-top: 175px;
  position: absolute;
}
#product-comparison-slider button.slick-next {
  background: transparent url(../Images/pfeil_rechts.svg) no-repeat right center;
  height: 96px;
  width: 34px;
  border: none;
  right: 0;
  margin-top: 175px;
  top: 0;
  position: absolute;
  right: -40px;
}
#product-comparison-slider button span,
#product-comparison-slider button.slick-disabled {
  display: none !important;
}
.products.zubehoer img {
  width: 50%;
}
.products.zubehoer h2,
.products.zubehoer h5 {
  font-size: 20px;
}
.products.zubehoer .pb30 {
  padding-bottom: 0;
}
.products.zubehoer hr {
  margin: 25px 0 50px 0;
}
.products.zubehoer table {
  margin-bottom: 20px;
}
.facet-option {
  padding: 2px 0 !important;
}
.facet-option a {
  background: none !important;
  padding: 0 5px 0 0 !important;
}
.facet-option:hover {
  background-color: #f2f2f2;
}
#filter-area {
  width: inherit;
  background-color: #ffffff;
  padding-bottom: 15px;
}
.affix {
  position: fixed;
  top: 0;
  z-index: 999;
}
@media screen AND (min-width: 768px) {
  .affix + .products {
    padding-top: 220px;
  }
}

.affix hr {
  display: none;
}
#filter {
  background-color: #ffffff;
  top: 0;
  width: inherit;
  z-index: 999;
}
#filter-area dl {
  margin-bottom: 10px;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
}
#product-comparison-slider .slick-list {
  user-select: text;
}
.tx-irfaq-toggle-plus {
  background: none !important;
}
.tx-irfaq-toggle-minus {
  background: url(../Images/iconMinus.svg) left top no-repeat !important;
  background-size: 20px 20px !important;
  padding-left: 25px !important;
}
.panel-title a {
  background: url('../Images/iconPlus.svg') left top no-repeat !important;
  background-size: 17px !important;
  padding-left: 24px !important;
}
.tx-irfaq-dynheader h4 a {
  margin: 2px 0 10px 0 !important;
  font-weight: 700 !important;
  color: var(--gray-700) !important;
  font-size: 16px !important;
}
.panel-group .panel {
  border-radius: 0;
}
.slick-track > p {
  margin: 20px 50px;
}
.toggleBox > .csc-header:hover {
  cursor: pointer;
}
.toggleBox > .csc-header:hover {
  cursor: pointer;
}

/* Gallery */
a.thumb:focus,
div.navigation a.pageLink:focus,
div.slideshow a.advance-link:focus {
  outline: 0;
}
div.image-title,
div.pagination span.current {
  font-weight: 700;
}
div#container {
  overflow: hidden;
}
div.content {
  display: none;
  clear: both;
}
div.content a,
div.navigation a {
  text-decoration: none;
}
div.content a:active,
div.content a:hover {
  text-decoration: underline;
}
div.pagination a:hover,
div.slideshow a.advance-link:active,
div.slideshow a.advance-link:hover,
div.slideshow a.advance-link:visited {
  text-decoration: none;
}
div.navigation a.pageLink {
  height: 77px;
  line-height: 77px;
}
div.controls {
  margin-top: 5px;
  height: 23px;
}
div.controls a {
  padding: 5px;
}
div.ss-controls {
  float: left;
}
div.nav-controls {
  float: right;
}
div.loader,
div.slideshow a.advance-link,
div.slideshow-container {
  width: 510px;
}
div.caption-container,
div.loader,
div.slideshow a.advance-link {
  min-height: 200px;
}
div.slideshow-container {
  position: relative;
  clear: both;
  float: left;
  width: 100%;
  margin: 20px 0 20px 0;
  min-height: 350px;
  overflow: hidden;
}
div.loader {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../images/loader.gif);
  background-repeat: no-repeat;
  background-position: center;
}
#slideshow {
  max-height: 380px;
  height: 380px;
}
div.slideshow span.image-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
div.slideshow a.advance-link {
  display: block;
  width: 100%;
}
div.slideshow img {
  width: 100%;
  height: auto;
}
div.caption-container {
  float: right;
  position: relative;
  margin-top: 30px;
}
div.navigation,
div.navigation a.pageLink,
div.navigation-container,
ul.thumbs {
  position: relative;
  float: left;
}
span.image-caption {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
div.caption-container,
span.image-caption {
  width: 334px;
}
div.caption {
  padding: 0 12px;
}
div.image-title {
  font-size: 1.4em;
}
div.image-desc {
  line-height: 1.3em;
  padding-top: 12px;
}
div.download {
  margin-top: 8px;
}
div.photo-index {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 12px;
}
div.navigation-container {
  left: 50%;
}
div.navigation {
  left: -50%;
}
div.navigation a.pageLink {
  display: block;
  margin: 2px;
  width: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

ul.thumbs,
ul.product-gallery {
  margin: 0;
  padding: 0;
}
ul.thumbs li,
ul.product-gallery li {
  float: left;
  padding: 0;
  margin-bottom: 30px;
  margin-right: 6px;
  list-style: none;
}
a.thumb,
ul.product-gallery li a {
  padding: 1px;
  display: block;
  border: 1px solid #c1c1c1;
}
ul.thumbs img,
ul.product-gallery li img {
  border: none;
  display: block;
}
div.pagination {
  clear: both;
  position: relative;
  left: -50%;
}
div.pagination a,
div.pagination span.current,
div.pagination span.ellipsis {
  position: relative;
  display: block;
  float: left;
  margin-right: 2px;
  padding: 4px 7px 2px;
  border: 1px solid #ccc;
}
div.pagination span.ellipsis {
  border: none;
  padding: 5px 0 3px 2px;
}
div.gallery-gutter {
  clear: both;
  padding-bottom: 20px;
}
div.caption-container {
  color: #eee;
}
div.image-title {
  font-weight: 700;
  font-size: 1.4em;
}
div.image-desc {
  line-height: 1.3em;
  padding-top: 12px;
}
div.download {
  margin-top: 8px;
}
div.photo-index {
  color: #888;
}
div.navigation a.prev {
  background-image: url(prevPageArrowWhite.gif);
}
div.navigation a.next {
  background-image: url(nextPageArrowWhite.gif);
}
div.slideshow img {
  border-color: #333;
}
ul.thumbs li.selected a.thumb {
  background: #fff;
  border: 1px solid #f59e0c;
}
div.pagination a:hover {
  background-color: #111;
}
div.pagination span.current {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
div.imgBgGray {
}

/*
.pictogramme > div {margin: 0 3px 12px 0;}
.pictogramme .budget {display:none;float:left; background:url(../Images/budget.svg) no-repeat right center; background-size: 100%; height:35px; width:35px}
.pictogramme .budget.active1 {display:block;background:url(../Images/budget_active.svg) no-repeat;}
.pictogramme .grafik {display:none;float:left; background:url(../Images/3D.svg) no-repeat right center; background-size: 100%; height:35px; width:35px}
.pictogramme .grafik.active1 {display:block;background:url(../Images/3D_active.svg) no-repeat;}
.pictogramme .speed {display:none;float:left; background:url(../Images/speed.svg) no-repeat right center; background-size: 100%; height:35px; width:35px}
.pictogramme .speed.active1 {display:block;background:url(../Images/speed_active.svg) no-repeat;}
.pictogramme .gewicht {display:none;float:left; background:url(../Images/gewicht.svg) no-repeat right center; background-size: 100%; height:35px; width:35px}
.pictogramme .gewicht.active1 {display:block;background:url(../Images/gewicht_active.svg) no-repeat;}
.pictogramme .akku {display:none;float:left; background:url(../Images/akku.svg) no-repeat right center; background-size: 100%; height:35px; width:35px}
.pictogramme .akku.active1 {display:block;background:url(../Images/akku_active.svg) no-repeat;}
.pictogramme span {display: none; position: relative; z-index: 9999;
    background-color: #fff;padding: 5px 15px;}
.pictogramme .active1:hover span {-webkit-box-shadow: 0px 0px 3px 1px rgba(214,210,214,1);
    -moz-box-shadow: 0px 0px 3px 1px rgba(214,210,214,1);
    box-shadow: 0px 0px 3px 1px rgba(214,210,214,1)}
*/
.dropdown dd,
.dropdown dt,
.dropdown ul {
  margin: 0px;
  padding: 0px;
}
.dropdown dd {
  position: relative;
}
.dropdown dt a {
  background: #ffffff url(../Images/updown.svg) no-repeat scroll right 3px center;
  background-size: 24px 24px;
  color: var(--gray-700) 0;
  font:
    normal 400 14px/20px 'Roboto',
    sans-serif;
  display: block;
  padding-right: 30px;
  border: 1px solid #f2f2f2;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  margin-right: 5px;
}
.dropdown dt a:hover {
  text-decoration: none;
}
.dropdown dt a {
  cursor: pointer;
  display: block;
  padding: 5px;
}
.dropdown dd ul {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #f2f2f2;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  display: none;
  list-style: none;
  padding: 5px;
  position: absolute;
  left: 0px;
  top: -1px;
  width: auto;
  min-width: 99%;
  z-index: 99999;
}
.dropdown span.value {
  display: none;
}
.dropdown dd ul li {
  padding: 10px 0;
}
.dropdown dd ul li a {
  padding: 0 15px 0 0;
  background: url('../Images/externalLink.svg') center right no-repeat;
  background-size: 10px 10px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 10px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 10px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 10px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 10px;
}
.slider-range {
  margin-top: -32px;
  padding-right: 5px;
}
.slider-range label {
  font-weight: 400;
}

.ui-widget-header {
  background: none;
  background-color: #f2f2f2;
}
.ui-widget-content {
  background: none;
  background-color: #bcbcbc;
  border: 10px solid #f2f2f2;
  height: 32px;
}
.ui-corner-all {
}
#facet-price_with_o_s_floatS-value,
#facet-price_without_o_s_floatS-value {
  width: inherit;
  float: left;
  margin: 0 0 0 33%;
}
.ui-slider .ui-state-default {
  border: none;
  background: #bcbcbc url('../Images/regler_links.svg') 100% 100% no-repeat;
  font-weight: normal;
  color: #555555;
  width: 35px;
  height: 21px;
  top: -0.25em;
}
.ui-slider .ui-state-default:last-child {
  border: none;
  background: #bcbcbc url('../Images/regler_rechts.svg') 100% 100% no-repeat;
  font-weight: normal;
  color: #555555;
  width: 35px;
  height: 21px;
  top: -0.25em;
  margin-left: -1.5em;
}
#tx-solr-faceting a {
  font-weight: normal;
}
#tx-solr-faceting .formLabel a {
  font-weight: 700;
}
#tx-solr-faceting a {
  font-weight: normal;
}

.tx-solr .tx-pagebrowse-pi1 {
  margin-bottom: 20px;
}
#headingOne .formLabel {
  padding-bottom: 15px;
}
/*ul.facets {
    margin: 0;
    list-style: none;
}*/
.remove-filter a {
  background: transparent url(../Images/close.svg) no-repeat right center;
  height: 20px;
  float: right;
  margin-right: 0px;
  padding-right: 30px;
}

/*div.imgBgGray .csc-textpic-center-outer {display: table;position: absolute;height: 100%;width: 100%}
    div.imgBgGray .csc-textpic-center-outer .csc-textpic-center-inner { display: table-cell; vertical-align: middle;}*/
div.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
  margin-bottom: 0 !important;
}
article div.csc-textpic .csc-textpic-imagewrap img {
  margin: 50px 0 0 0;
}
.sidebar div.csc-textpic .csc-textpic-imagewrap img {
  margin: 0;
}
article figure img {
  max-width: 100%;
  height: 100%;
}
.affix {
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--gray-500);
  height: 30px;
}
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--gray-600);
}
.form-horizontal .control-label {
  padding-top: 4px;
}
.Tx-Formhandler .error {
  color: var(--primary);
}
.Tx-Formhandler .error input,
.Tx-Formhandler .error textarea,
.Tx-Formhandler .error select {
  border-color: var(--primary);
}
.form-horizontal .control-label {
}
.btn,
.external-link-btn {
  color: #ffffff;
  background: var(--primary);
  border-radius: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 20px;
}
ul.f3-widget-paginator {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.f3-widget-paginator li {
  float: left;
  margin-right: 10px;
}
.mfp-iframe-holder .mfp-close {
  color: var(--primary) !important;
  font-weight: 700 !important;
  top: 20px !important;
  height: 30px;
  font-size: 14px;
  background: url('../Images/close.svg') right center no-repeat;
  background-size: 15px 15px;
  right: 0 !important;
  opacity: 1;
  width: initial;
  line-height: initial;
  margin-right: 20px !important;
  padding-right: 20px !important;
}

.bgGray,
.bgLightGray {
  padding: 10px;
}
.bgGray img,
.bgLightGray img {
  margin: 0 !important;
}
.bgGray h2,
.bgLightGray h2 {
  margin: 3px 0 !important;
  float: right;
  font-size: 15px;
}
.bgGray {
  background-color: #f2f2f2;
}
.bgLightGray {
  background-color: #fcfcfc;
}

#p2 .sidebar h2.csc-firstHeader {
  font-weight: 700;
}
#p9 .sidebar .csc-textpic-text ul {
  margin: 0 0 0 20px;
}
#p9 .sidebar .csc-textpic-text p {
  margin: 0;
}
#p6 article div.csc-textpic .csc-textpic-imagewrap img {
  margin: 10px 0 0 0;
}

/* FOOTER
    ----------------------------------------------------------------------------------------------------------------------------- */
footer {
  margin-top: 100px;
  font-size: 12px;
  line-height: 12px;
}
footer p {
  margin: 0;
}
footer ul {
  margin: 0;
  list-style-type: none;
}
footer li {
  float: left;
  padding: 0 10px 0 0;
}
footer li:last-child {
  border: none;
}
footer li {
  float: left;
}
footer li a {
  text-transform: uppercase;
  color: var(--gray-700);
  border-right: 1px solid #9c9c9c;
  margin: 0;
  padding: 0 10px 0 0;
  line-height: 30px;
}
footer li:last-child a {
  border: none;
}
footer a {
  color: var(--gray-700);
}

/* MEDIA QUERIES
----------------------------------------------------------------------------------------------------------------------------- */
@media (max-width: 414px) {
  // wer bootstrap klassen verändert oder fälscht, oder veränderte oder verfälschte bootstrap klassen in umlauf bringt, wird
  // mit freiheitsstrafe nicht unter 5 jahren bestraft!
  .container {
    //padding: 0;
  }
  .topButtons {
    margin-right: -20px;
  }
  .navbar.noPadding {
    padding: 0;
  }
  .slick-slider {
    /*position: absolute;left: -70px !important;*/
  }
  .logos a {
    padding-right: 0px;
    padding-left: 10px;
  }
  #product-comparison-slider button.slick-next {
    right: -5px;
  }
  .product-comparison table tr td {
    border-left: none;
    padding-left: 60px;
    padding-right: 0;
  }
  .product-comparison table tr:last-child td {
    border-left: none;
    padding-left: 60px;
    padding-right: 0;
  }
  .product-comparison table tr:nth-child(1) td {
    border-left: none;
    padding-left: 0px;
  }
  .product-comparison table tr:nth-child(2) td {
    border-left: none;
    padding-left: 0px;
  }
  .product-comparison table tr:nth-child(24) td {
    border-left: none;
    padding-left: 0px;
  }
}

@media (min-width: 415px) AND (max-width: 690px) {
  .slick-slider {
    /*position: absolute;left: -90px !important;*/
  }
}

@media (max-width: 767px) {
  .slider .status {
    margin-top: 0;
  }
  .navbar {
    margin-left: 0;
  }
  .topButtons.pull-right {
    float: right;
    margin-right: -25px;
  }
  .blockSupport,
  .blockForum,
  .blockAnmeldung,
  .blockTeilnehmer {
    text-align: center;
  }
  .icon {
    height: 80px;
  }
  .logos li {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .logos li a {
    display: inline-block;
  }
  .btnDetails {
    margin: 20px 0 5px 0;
  }
  .productPreis {
    text-align: center;
    margin-bottom: 10px;
  }
  .productPreis p {
    color: var(--gray-700);
    margin-bottom: 0;
  }
  //.btnPrint{clear:left;float:left!important;margin-top:7px;display:none;}
  .productDetails .btnBuy {
    margin-bottom: 20px;
  }
  footer p {
    text-align: left;
    margin-bottom: 20px;
    line-height: 150%;
  }
  footer ul {
    float: none;
    text-align: left;
    margin: 20px 0 40px 0px;
  }
  footer li {
    float: none;
    display: inline-block;
    padding: 0 5px;
  }
  footer li:first-child {
    padding: 0px 5px 0 0;
  }
  footer li:last-child {
    padding: 0px 0 0 5px;
  }
  //.row {margin: 0 ;}
  .ps30 {
    padding: 15px;
  }
  .pt15 {
    padding: 15px 0 0 0;
  }
  .subMenu .navbar-nav > li > a {
    padding: 12px 14px 8px 14px;
    border-bottom: 2px solid transparent;
  }
  .subMenu {
    height: auto;
  }
  .subMenu .navbar-nav {
    margin: 0;
  }
  #mainMenu ul li {
    float: none;
  }
  #mainMenu .navbar-nav {
    margin: 0;
  }
  #subMenu ul li {
    float: none;
  }
  .collaps {
    display: none;
  }
  .slider h3,
  .slider .h3 {
    font-size: 18px;
  }
  .slider h1 {
    font-size: 30px;
    line-height: 110%;
  }
  .slider .clocktext {
    font-size: 16px;
  }
  .slider .container {
    padding: 40px 10px;
  }
  /*.slick-slider {    position: absolute;left: -130px;}*/
  /*.tx-ubookproducts .slick-list { margin-left: 0px; position: absolute;left: 140px; }*/
  .product-comparison > div:first-child {
    border: none;
  }
  .tx-ubookproducts .product-comparison-caption {
  }
  .product-comparison .col-md-2 {
  }
  .product-comparison .col-md-10 {
  }
  .affix {
    position: relative !important;
    top: auto;
    z-index: 999;
  }
  .affix hr {
    display: block;
  }
  .sliderSmall .csc-textpic-center .csc-textpic-center-inner {
    position: initial;
  }
  .bgGray h2,
  .bgLightGray h2 {
    margin: 3px 0 !important;
    float: left;
    display: block;
    width: 100%;
  }
  .products.zubehoer img {
    width: 100%;
  }
  .formLabel a span {
    background: var(--primary) url('../Images/iconPlus.svg') no-repeat center center;
  }
  .formLabel a.collapsed span {
    background: var(--primary) url('../Images/iconMinus.svg') no-repeat center center;
  }
  .product-comparison table.product-comparison-caption tr td {
    border-left: none;
    text-align: left;
    padding: 3px 0 20px 0;
  }
  .product-comparison table.product-comparison-caption tr.product-comparison-tr-header td {
    padding: inherit;
  }
  .product-comparison table tr td {
    border-left: none;
  }
  .product-comparison .slick-slide table,
  .slick-initialized .slick-slide {
    max-width: calc(100vw - 60px);
  }
}
@media (min-width: 768px) {
  footer ul {
    float: right;
  }
}
@media (max-width: 991px) {
  .slider-range {
    margin-top: inherit;
  }
}
@media (max-width: 1140px) {
  .slider div.csc-textpic-center .csc-textpic-imagewrap,
  div.csc-textpic-center figure.csc-textpic-imagewrap {
    float: left;
  }
  span.formLabel {
    margin-bottom: 5px;
  }
  .slider-range {
    margin-top: -12px;
  }
  .dropdown dd ul {
    position: relative;
    width: 95%;
  }
}

@media print {
  .topButtons,
  .mainMenu,
  .subMenu,
  footer nav,
  .btnPrint {
    display: none;
  }
  img {
    width: 50%;
  }
}

/* Module: dropdownLinklist
----------------------------------------------------------------------------------------------------------------------------- */

.dropdownlink {
  border: 1px solid #f2f2f2;
  margin-bottom: 15px;
  border-radius: 2px 2px 2px 2px;
  margin-right: 5px;
  overflow: hidden;
  background: url('../Images/updown.svg') no-repeat scroll right 3px center;
  background-size: 24px 24px;
  cursor: pointer;
}

.dropdownlinklist {
  width: 111%;
  border: none;
  padding: 5px;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
  -moz-appearance: none;
}

.dropdownlinklist:focus {
  width: 100%;
}

.dropdownlinklist select:focus {
  outline: none;
}

.dropdownlinklist option {
}

//.tooltip-simple span {
//    position: fixed;
//    display: none;
//    font: normal 400 15px/22px'Roboto',sans-serif;
//    width: 250px;
//    margin-top: -40px;
//    padding: 5px;
//    z-index: 999999999999;
//    margin-left: 16px;
//    background: #eee;
//}

#rotator,
#rotator2,
#rotator3,
#rotator4 {
  display: block;
  overflow: hidden;
}
#rotator img,
#rotator2 img,
#rotator3 img,
#rotator4 {
  max-width: 100%;
}

.tx-pagebrowse {
  text-align: center;
  list-style: none inside none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.tx-pagebrowse li {
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0 5px 0 0;
}
li.tx-pagebrowse-pages {
  width: 80%;
}
li.tx-pagebrowse-pages ol {
  display: inline;
  margin: 0;
  padding: 0 10px;
}

/* language menu  */
.language-menu-mobile {
  display: none;
}
@media (max-width: 767px) {
  .language-menu-desktop {
    display: none;
  }
  .language-menu-mobile {
    display: block;
  }
  .language-menu-mobile {
    float: none !important;
  }
  #mainMenu #language_menu li {
    float: left;
  }
}

#c732 h2.csc-firstHeader {
  font-family: 'Bosk', Arial, sans-serif;
  font-size: 2.4em;
  font-weight: 300;
}
