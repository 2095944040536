button {
  &.btnPrint {
    font-size: 14px;
    color: var(--primary);
    padding-right: 25px;
    background: url(../Images/printer.svg) no-repeat right center;
    background-size: 15px 15px;
    border: none;
  }
}

.btnDetails,
.btnBuy,
.btn {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 10px 5px;

  &:hover {
    text-decoration: none;
  }

  &.btn-inline {
    display: inline;
  }

  &.btn-gray {
    background: var(--gray-600);
    color: var(--white);

    &:hover {
      background-color: var(--gray-900);
    }
  }

  &.btn-link {
    color: var(--primary);
    background: none;
  }
}

.btnDetails {
  background-color: var(--gray-600);
  margin-right: 5px;

  &:hover {
    background-color: var(--gray-700);
  }
}

.btnBuy {
  background-color: var(--primary);
  margin-bottom: 5px;

  &:hover {
    background-color: var(--primary-aa);
  }

  &:focus {
    outline-color: var(--gray-600);
  }
}
