.news-backlink {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  color: var(--primary);

  &:hover {
    text-decoration: underline;
  }

  & > * + * {
    margin-left: 5px;
  }

  &-wrap {
    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.article {
  .more {
    svg {
      width: 12px;
      height: 12px;
      transform: translateY(-2px);
    }
  }
}
