ul {
  &.fancy-list-styles {
    list-style: none;

    li {
      position: relative;
      padding-left: $grid-gutter-width * 2;
      margin-bottom: $grid-gutter-width / 2;

      &:before {
        content: '';
        background: url(../Icons/arrows.svg) no-repeat center;
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 25px;
      }
    }
  }

  &.orange-square {
    li {
      list-style-type: square;
      color: map-get($theme-colors, orange);
    }
  }
}
