.productDetails {
  //.panel-heading {
  //  a {
  //    // Remove "plus-icon" which is used in faq's frontend
  //    background: none !important;
  //    padding-left: 0 !important;
  //  }
  //}

  .manufacturer {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($theme-colors, orange);
  }

  .productname {
    color: map-get($theme-colors, grey);
    font-weight: 400;
    margin: 2px 0 3px;
    padding: 0;
    font-size: 28px;
  }

  .productPreis {
    display: flex;
    flex-direction: column;

    .product-desc {
      margin-bottom: 15px;
    }

    .product-price {
      color: map-get($theme-colors, orange);
      font-size: 25px;
      font-weight: 500;
    }
  }

  .btnBack svg {
    width: 15px;
    height: 15px;
  }
}
