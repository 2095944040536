.ce-textpic {
  .ce-bodytext {
    h2 {
      margin-bottom: 10px;
    }

    ul {
      margin: 0 0 10px 20px;
      padding: 0;
      overflow: initial;
    }
  }

  &.ce-right {
    h2 {
      margin-bottom: 25px;
    }
  }
}
