.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0 0 15px 0;
}

.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
}

.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
}

.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
}
