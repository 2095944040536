@each $breakpoint in map-keys($supi-breakpoints) {
  @include supi-media-breakpoint-up($breakpoint) {
    $infix: supi-breakpoint-infix($breakpoint, $supi-breakpoints);

    @each $prop, $abbrev in (margin: supi-m, padding: supi-p) {
      @each $size, $length in $supi-spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    @each $size, $length in $supi-spacers {
      @if $size != 0 {
        .supi-m#{$infix}-n#{$size} {
          margin: -$length !important;
        }
        .supi-mt#{$infix}-n#{$size},
        .supi-my#{$infix}-n#{$size} {
          margin-top: -$length !important;
        }
        .supi-mr#{$infix}-n#{$size},
        .supi-mx#{$infix}-n#{$size} {
          margin-right: -$length !important;
        }
        .supi-mb#{$infix}-n#{$size},
        .supi-my#{$infix}-n#{$size} {
          margin-bottom: -$length !important;
        }
        .supi-ml#{$infix}-n#{$size},
        .supi-mx#{$infix}-n#{$size} {
          margin-left: -$length !important;
        }
      }
    }

    // Some special margin utils
    .supi-m#{$infix}-auto {
      margin: auto !important;
    }
    .supi-mt#{$infix}-auto,
    .supi-my#{$infix}-auto {
      margin-top: auto !important;
    }
    .supi-mr#{$infix}-auto,
    .supi-mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .supi-mb#{$infix}-auto,
    .supi-my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .supi-ml#{$infix}-auto,
    .supi-mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}
