h1,
.h1 {
  font-size: 30px;
  margin: 25px 0 25px 0;
  font-weight: 400;
  color: var(--gray-700);
}

h2,
.h2 {
  margin: 50px 0 25px 0;
  font-size: 24px;
  font-weight: 400;
  color: var(--gray-700);
}

h3,
.h3 {
  margin: 0 0 10px 0;
  font-size: 22px;
  font-weight: 400;
  color: var(--gray-700);
}

h4,
.h4 {
  margin: 0 0 8px 0;
  font-size: 20px;
  font-weight: 500;
  color: var(--gray-700);
}

.rootpage .frame-type-text:first-of-type h1,
.rootpage .frame-type-text:first-of-type h2 {
  font-family: Bosk, Arial, sans-serif !important;
  font-size: 2.4em;
  font-weight: 300;
  margin-top: 30px;
}
