header {
  .mobile-navigation {
    position: fixed;
    right: 0;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      position: relative;
      right: auto;
    }
  }
}
