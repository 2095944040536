@use 'sass:math';

@import '../../../../../../vendor/in2code/powermail/Resources/Public/Css/Basic';

.powermail {
  &_confirmation {
    .powermail_all_label {
      @include media-breakpoint-up(sm) {
        width: math.percentage(math.div(3, 12));
      }
    }
  }
}
