.loggedin {
  &-info {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;

    &__tooltip {
      border: 1px solid #ddd;
      border-radius: 2px;
      position: absolute;
      display: block;
      padding: 5px;
      bottom: 0;
      right: 0;
      width: 10em;
      background: #f0f0f0;
      transform: translate(0%, 100%);
      z-index: 10;
    }

    &:hover,
    &:focus {
      overflow: visible;
    }

    &:hover &__tooltip,
    &:focus &__tooltip {
      transform: translate(0%, 100%);
    }
  }
}
