@media print {
  body {
    font-family: arial, no-serif;
    margin: 50px 50px 100px 90px;
    line-height: 130%;
    width: 100%;
  }
  .slider,
  .topButtons,
  .mainMenu,
  .subMenu,
  footer nav,
  .btnPrint,
  .breadcrumbs,
  .pictogramme,
  #thumbs,
  .btnBuy,
  .addToCompareDetail,
  .addToCompare,
  .btnMore,
  .btnDetails,
  input[type='checkbox'],
  #filter-area,
  hr,
  button,
  .btnBack,
  .product-comparison-caption {
    display: none !important;
  }
  .slideshow-container {
    height: auto !important;
  }
  .productPreis {
    margin-right: 20px;
    font-size: 16px;
  }
  #accordion {
    float: left;
    font-size: 13px;
  }
  a {
    color: #000000;
  }
  footer,
  .sidebar {
    float: left;
  }
  .article {
    margin-bottom: 20px;
  }
  .product {
    width: 70%;
    clear: both;
    margin-bottom: 120px;
  }
  .product table tr {
    height: auto !important;
  }
  .product table tr td p {
    margin: 2px;
  }
  .product-comparison-slider {
    width: 50%;
  }
  .showPrint {
    font-weight: 700;
    display: block;
    float: left;
    margin-right: 5px;
  }
  .productPreis h5 {
    font-size: 16px;
    margin-top: 5px;
  }
  .slick-track {
    transform: inherit;
  }
}

/** product comparison page; with FE Layout Option set */
@media print {
  :focus {
    outline: none;
  }

  body.layout-1 {
    margin: 0;

    .tx-ubookproducts > h1,
    .navbar-brand {
      float: none;
      margin: 0;

      img {
        width: auto;
      }
    }

    .tx-ubookproducts > h1 {
      top: 60px;
      right: 10px;
    }

    .product {
      width: 100% !important;
      clear: both;
      margin: 0;

      img {
        height: 200px !important;
        width: auto;
      }

      table {
        width: calc(100% - 230px) !important;
        border-collapse: collapse;
        page-break-after: always;

        tr {
          height: auto !important;

          td {
            .product-remove {
              display: none;
            }
          }

          &[class] {
            td {
              border: 1px solid #000;
              padding: 0;
            }

            &.tr1 {
              td {
                border: none;
                padding-bottom: 30px;
              }
            }
          }
        }
      }
    }

    .slick-track {
      width: calc(100% - 16px) !important;
    }

    .hidden-print,
    form#TSFE_ADMIN_PANEL_FORM {
      display: none !important;
    }
  }
}
