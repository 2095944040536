.ce-gallery {
  .sidebar .ce-above & {
    margin-bottom: 0;
  }

  .sidebar .ce-textpic.ce-left.ce-intext & {
    margin-right: 10px;
  }

  .ce-column {
    margin-right: 10px;

    .sidebar &,
    .ce-image & {
      margin-right: 0;
    }

    .logos & {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    figure img {
      max-width: 100%;
      height: auto;
    }
  }

  .ce-textpic.ce-intext.ce-left & {
    margin-right: 0;
    margin-bottom: 0;
  }

  .ce-textpic.ce-intext.ce-right & {
    @media (max-width: #{map-get($grid-breakpoints, 'sm') - 1}) {
      float: none;
    }

    margin-left: 0;
    margin-bottom: 0;

    .ce-column {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  article .ce-textpic & img,
  article .ce-image & img {
    margin: 50px 0 0;
  }

  .sidebar .ce-textpic & img {
    margin-top: 0;
  }
}
