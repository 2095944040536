.slider {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 372px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-position: top center;
    height: auto;
    min-height: 372px;
    background-size: cover !important;
    // It is a temporary workaround with !important because of
    // inline styles in head by customHeaderTags.txt in Overrides.typoscript
    padding: 0 $grid-gutter-width / 2 !important;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    & > * {
      position: relative;
      z-index: 2;
    }

    h1,
    .h3,
    .clocktext {
      color: var(--gray-700) !important;
      text-shadow: none !important;
    }
  }

  &Small {
    height: auto;
    min-height: 170px;

    .container {
      height: auto;
      min-height: 170px;
    }

    .slider {
      height: auto;
      min-height: 170px;
    }
  }
}
