$supi-position: (
  bottom: 0,
  left: 0,
);

$supi-colors: (
  background: #fff,
  primary: #404040,
  secondary: #ccc,
  button-bar: #efefef,
  success: #f49900,
  warning: #f2f2f2,
  notice: #404040,

  button-wrap-bgcolor: #efefef,
);

$supi-border: false;

@import '../../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

:root {
  --supi-button-wrap-padding: 32px;

  @media (min-width: $supi-breakpoint) {
    --supi-position-width: 100%;
  }
}

.tx-supi {
  &-overlay {
    z-index: 999999999;
  }

  &.hide {
    @extend .d-none;
  }

  &.position-center-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__label {
    margin-top: 0;
  }

  &__block-item {
    margin: 10px 0;

    &__text {
      & > * + * {
        margin-top: 10px;
      }
    }

    &__table {
      & > * {
        th {
          @include media-breakpoint-up(sm) {
            flex-basis: 200px;
          }
        }
      }
    }
  }

  button#supi__choose {
    background: var(--primary);
    color: var(--white);
    font-weight: 700;
  }

  .supi-checkbox label {
    color: var(--primary);
  }

  .supi-checkbox input:checked + label {
    background: currentColor;
    box-shadow:
      inset 0 0 0 0 rgba(0, 0, 0, 0.2),
      0 0 0 2px currentColor;
  }

  .supi-checkbox input:checked + label:before {
    box-shadow:
      1px 1px rgba(0, 0, 0, 0.2),
      0 0 0 2px var(--primary);
  }
}
