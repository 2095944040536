.main-nav {
  background: var(--gray-200);
  height: 0;
  visibility: hidden;
  overflow: hidden;

  body.contrast & {
    background: var(--white);
    border-top: 1px solid var(--black);
  }

  @include motion-safe {
    transition:
      height 0.3s ease,
      visibility 0s ease 0.3s;
  }

  &--active {
    height: unset;
    visibility: visible;

    @include motion-safe {
      transition-delay: 0s, 0s;
    }
  }

  @include media-breakpoint-up(md) {
    height: unset;
    visibility: unset;
    overflow: unset;
    transition: none;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    margin: 0;
    list-style: none;
    flex-direction: column;
    padding: 0;
    height: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &--language {
      flex-direction: row;
      margin-left: $grid-gutter-width / 2 * -1;

      @include media-breakpoint-up(md) {
        margin-left: 0;
      }
    }
  }

  &__list-item {
    display: flex;
    align-items: stretch;
    padding: 0 $grid-gutter-width / 2;
    margin: 0 $grid-gutter-width / -2;
    height: 100%;

    @include motion-safe {
      transition: background-color 0.2s ease;
    }

    @include media-breakpoint-up(md) {
      margin: 0;
      padding: 0;

      &:hover {
        background: var(--gray-300);
      }
    }

    &--language {
      margin: 0;
      padding: 0;
    }
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    padding: $grid-gutter-width / 2 0;
    font-size: 1.125rem;

    &,
    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      padding: 0 $grid-gutter-width / 2;
    }

    &--active {
      &,
      &:hover,
      &:focus {
        color: var(--primary);

        body.contrast & {
          font-weight: bold;
        }
      }
    }

    &--language {
      padding: $grid-gutter-width / 2;
      text-transform: uppercase;
    }
  }

  &__submenu {
    &-toggle {
      display: flex;
      align-items: center;
      margin: 0 0 0 $grid-gutter-width / 2;
      border: 0;
      padding: 0;
      flex-shrink: 0;
      flex-grow: 0;
      width: $grid-gutter-width;
      background: transparent;

      & * {
        pointer-events: none;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }

      &-icon {
        position: relative;
        display: block;
        aspect-ratio: 1 / 1;
        background: var(--gray-300);
        width: 100%;
        border-radius: 50%;
        transform: rotateZ(180deg);

        @include motion-safe {
          transition: transform 0.2s ease;
        }

        &::before,
        &::after {
          display: block;
          position: absolute;
          width: 35%;
          height: 2px;
          background: var(--gray-700);
          content: '';
          top: 35%;
        }

        &::before {
          left: 15.75%;
          transform: rotateZ(-45deg);
          transform-origin: right center;
        }

        &::after {
          right: 15.75%;
          transform: rotateZ(45deg);
          transform-origin: left center;
        }
      }

      &--active &-icon {
        transform: none;
      }
    }

    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
      border-bottom: 1px solid var(--gray-500);
      height: 0;
      visibility: hidden;
      overflow: hidden;

      @include motion-safe {
        transition:
          height 0.3s ease,
          visibility 0s ease 0.3s;
      }

      &--active {
        height: unset;
        visibility: visible;

        @include motion-safe {
          transition-delay: 0s, 0s;
        }
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &-link {
      display: block;
      padding: $grid-gutter-width / 8 0;

      &,
      &:hover,
      &:focus {
        color: inherit;
      }

      &--active {
        &,
        &:hover,
        &:focus {
          color: var(--primary);

          body.contrast & {
            font-weight: bold;
          }
        }
      }
    }
  }
}
