.product-comparison {
  table {
    tr {
      th,
      td {
        border: 1px solid var(--gray-300);
        border-right: none;
        border-collapse: collapse;
        padding: 19px 15px;
        vertical-align: top;

        p {
          font-size: 14px;
          margin: 0;
        }
      }

      th {
        border-left: none;
      }

      &:last-child {
        th,
        td {
          border: none;
        }
      }
    }
    thead {
      tr {
        th {
          border-left: 1px solid var(--gray-300);
          border-top: 0;
          &:first-child {
            border-left: 0;
          }
        }

        &:first-child {
          th,
          td {
            padding: 0;
            border: none;
          }
        }
      }
    }
  }
}
