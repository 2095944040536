@function supi-breakpoint-infix($name, $breakpoints: $supi-breakpoints) {
  @return if(supi-breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

@function supi-breakpoint-min($name, $breakpoints: $supi-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@mixin supi-media-breakpoint-up($name, $breakpoints: $supi-breakpoints) {
  $min: supi-breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
