.active-facet {
  display: flex;
  padding: $grid-gutter-width / 8 $grid-gutter-width / 4;
  background: var(--gray-200);
  border-radius: $grid-gutter-width / 8;
  font-weight: normal;
  transition: none;
  align-items: center;

  &,
  &:hover,
  &:focus {
    color: var(--black);
    text-decoration: none;
  }

  &:hover,
  &:focus {
    background: var(--gray-300);
  }

  @include motion-safe {
    transition: background-color 0.2s ease;
  }

  &__value {
    font-weight: bold;
    padding-left: 0.5ch;
  }

  &__icon {
    width: $grid-gutter-width / 2;
    height: $grid-gutter-width / 2;
    margin-left: $grid-gutter-width / 4;
  }
}
