.contrast-switch {
  display: flex;
  align-items: center;
  gap: $grid-gutter-width / 4;
  border: none;
  background: none;
  padding: 1.5rem;
  height: 100%;

  &__icon {
    display: block;
    transform-origin: center center;
    transform: rotateZ(30deg);
    width: $grid-gutter-width / 3 * 2;
    height: $grid-gutter-width / 3 * 2;

    @include motion-safe {
      transition: transform 0.2s ease;
    }
  }

  &--active &__icon {
    transform: rotateZ(-150deg);
  }

  &:hover {
    background: var(--gray-200);
  }

  &:focus {
    outline-offset: -6px !important;
  }
}
