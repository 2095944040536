/* Countdown */
#clock {
  margin-bottom: 40px;

  .date {
    background-color: var(--primary);
    color: #fff;
    height: auto;
    padding: 10px 0;
    width: 60px;
    margin: 1px;
    float: left;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.005em;

    span {
      display: block;
      color: var(--white);
      font-size: 36px;
      font-weight: 700;
      line-height: 1;
    }
  }
}

.clocktext {
  font-size: 18px;
  font-weight: 700;
}
