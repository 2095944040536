.pictogram {
  width: 35px;
  height: 35px;

  &.active {
    --gray-500: var(--primary);
  }
}

.pictogramme {
  & > * + * {
    margin-left: 0.25rem;
  }
}
