@each $bp, $size in $grid-breakpoints {
  @include media-breakpoint-down($bp) {
    .sr-#{$bp}-only {
      @include sr-only();
    }
  }
}

a,
button,
input[type='text'],
select,
.btn,
[tabindex='-1'] {
  &:focus-visible {
    box-shadow: none;
    outline-offset: 3px;
    outline: 2px dashed currentColor;
    opacity: 1;
    transition: none;
  }
}

input[id^='supi'],
input[id^='supi'][type='checkbox'],
input[type='checkbox'] {
  &:focus-visible {
    outline: 0;

    & + label {
      outline: 2px dashed currentColor;
      outline-offset: 3px;
    }
  }
}

.supi__buttongroup button[data-toggle] {
  outline-offset: -2px;
  outline-color: currentColor;
}

button[id^='supi'] {
  &:focus-visible {
    outline-color: currentColor;
  }
}

.main-nav__link:focus-visible,
.sub-nav__link:focus-visible,
.product-gallery li a:focus-visible,
.logos a:focus-visible {
  outline-offset: -2px;
}

.splide__pause,
.splide__play {
  outline-offset: -3px;
}

.btn.btn-gray:focus-visible {
  outline-color: var(--gray-600);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  box-shadow: none;
  outline-offset: 3px;
  outline: 2px dashed currentColor;
  opacity: 1;
  transition: none;
}

.js-focus-visible .logos a.focus-visible {
  outline-offset: -3px;
}

.js-focus-visible .btn.btn-gray.focus-visible {
  outline-color: var(--gray-600);
}

.js-focus-visible button[id^='supi'].focus-visible {
  outline-offset: 0;
  outline-color: __get($theme-colors, base);
}

.js-focus-visible .supi__buttongroup button[id^='supi'].focus-visible {
  outline-color: currentColor;
  outline-offset: -2px;
}

.js-focus-visible .splide__pause.focus-visible,
.js-focus-visible .splide__pause.focus-visible {
  outline-offset: -3px;
}

.js-focus-visible input[id^='supi'],
.js-focus-visible input[type='checkbox'].compare,
.js-focus-visible input[type='checkbox'].checkbox-compare {
  outline: none;

  & + label {
    outline: 2px dashed transparent;
    outline-offset: 3px;
  }

  &.focus-visible {
    & + label {
      outline-color: currentColor;
    }
  }
}

.js-focus-visible .main-nav__link.focus-visible,
.js-focus-visible .sub-nav__link.focus-visible,
.product-gallery li a.focus-visible {
  outline-offset: -2px;
}

.js-focus-visible .icon.focus-visible {
  outline-offset: 0;
}
