.solr-pagination,
.news-pagination {
  display: flex;
  width: 100%;
  justify-content: center;

  .pagination {
    padding: 0;
    display: flex;
    list-style: none;
    margin: $grid-gutter-width / 2 0;

    li {
      display: list-item;
      flex: 1 1 auto;
      align-self: center;

      & > a,
      & > span {
        border: none;
        border-radius: 0;
        float: none;
        font-weight: normal;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
      }

      & > a {
        color: var(--primary);

        & > span {
          color: inherit;
        }

        &:hover {
          background: var(--primary);
          color: var(--white);

          & > span {
            color: inherit;
          }
        }
      }

      &.active {
        a {
          font-weight: bold;
        }
      }

      &.next,
      &.previous {
        a,
        span {
          color: var(--primary);

          &:hover {
            background: var(--primary);
            color: var(--white);
          }
        }
      }
    }
  }
}
